<template>
  <moe-page title="新增优惠券">
    <moe-form
      class="default-form"
      ref="couponForm"
      :showBack="false"
      :showClose="true"
      :model="couponParams"
      :rules="rules">
      <div style="width: 50%;" class="margin-0-auto">
        <div class="fwb mb-20 font-20">基本信息</div>
        <el-form-item label="优惠券名称" prop="name" label-width="150px">
          <el-input class="w-300" v-model.trim="couponParams.name" placeholder="请输入优惠券名称，2到16个文字组合" clearable maxlength="16"></el-input>
          <div class="color-info font-10 lh1 mt-10">优惠券名称仅用于商家自己管理活动，不会显示给用户</div>
        </el-form-item>

        <el-form-item label="使用时间" prop="timeType" label-width="150px">
          <moe-radio-group :disabled="IS_EDIT" :radioBoxList="$moe_data.couponTimeTypeList" v-model="couponParams.timeType" @change="changeTimeType"></moe-radio-group>
        </el-form-item>

        <el-form-item label="" prop="startTime" label-width="150px" v-if="couponParams.timeType === 'TIME_RANGE'">
          <el-date-picker
            class="w-500"
            :disabled="IS_EDIT"
            placement="bottom-start"
            v-model="datetime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="(datetime) => ([couponParams.startTime, couponParams.endTime] = datetime || ['', ''])" />
        </el-form-item>

        <el-form-item label="" prop="effectiveDays" label-width="150px" v-if="couponParams.timeType === 'RELATIVE'">
          <el-input :disabled="IS_EDIT" class="w-300" placeholder="请输入" maxlength="9" :value="couponParams.effectiveDays" @input="(value) => couponParams.effectiveDays = $moe_formatter.formatterInteger(value)">
            <template slot="append">天有效</template>
          </el-input>
        </el-form-item>

        <el-form-item label="是否开放领取" prop="visible" label-width="150px">
          <moe-radio-group :radioBoxList="$moe_data.couponVisibleList" v-model="couponParams.visible"></moe-radio-group>
          <div class="color-info font-10 lh1 mt-10">（是否在商品详情展示）</div>
        </el-form-item>

        <div class="fwb mt-30 mb-30 font-20">优惠信息</div>

        <div class="df aic">
          <el-form-item label="满" prop="condition" label-width="150px">
            <el-input :disabled="IS_EDIT" class="w-200" placeholder="请输入金额" maxlength="9" :value="couponParams.condition" @input="(value) => changeCondition(value)">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>

          <el-form-item label="减" prop="discount" label-width="100px">
            <el-input :disabled="IS_EDIT" class="w-200" placeholder="请输入金额" maxlength="9" :value="couponParams.discount" @input="(value) => couponParams.discount = $moe_formatter.formatterMoney(value)">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </div>

        <div class="df aic">
          <el-form-item label="发行量" prop="number" label-width="150px">
            <el-input class="w-200" :value="couponParams.number" placeholder="请输入数量" maxLength="6" @input="(value) => couponParams.number = $moe_formatter.formatterInteger(value)"></el-input>
          </el-form-item>

          <el-form-item label="每人限领" prop="quota" label-width="100px">
            <div class="df aic">
              <moe-select class="w-200" type="couponQuota" v-model="couponParams.quota" placeholder="请选择限领数量"></moe-select>
              <div class="ml-10">张</div>
            </div>
          </el-form-item>
        </div>
      </div>

      <div style="height: 700px;min-height: 700px;" class="df fdc" v-if="IS_SHOP_COUPON">
        <moe-table
          :stripe="true"
          :key="itemKey"
          :numberShow="false"
          :data="couponParams.configList"
          :mode="false"
          :showPage="false"
          :params="{ pageNum: 1 }"
          emptyText="请添加活动商品"
          rowKey="goodsId">
          <!-- 操作按钮 -->
          <el-form-item prop="configList" slot="tool">
            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showDialog = true;">添加商品</el-button>
          </el-form-item>


          <el-table-column label="商品信息" min-width="200">
            <template slot-scope="{ row }">
              <div class="df aic">
                <moe-image :src="row.coverUrl" width="80px" height="80px" />
                <div class="df1 tal ml-10">
                  <p class="fwb">{{ row.name }}</p>
                  <p class="font-12 color-info">商品编号: {{ row.goodsId }}</p>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="销售价" min-width="80">
            <template slot-scope="{ row }">
              {{ `￥ ${row.minPrice}` }}{{ $moe_math.mathGreaterThan(row.maxPrice, row.minPrice) ? ` - ¥ ${row.maxPrice}` : '' }}
            </template>
          </el-table-column>

          <el-table-column prop="stock" label="库存" min-width="80" />

          <el-table-column label="操作" width="200" fixed="right">
            <template slot-scope="{ row, $index }">
              <el-button :disabled="row.id ? true : false" icon="el-icon-delete" size="small" type="danger" @click="handleDelete(row, $index)">撤出活动</el-button>
            </template>
          </el-table-column>
        </moe-table>
      </div>

      <template slot="tool">
        <el-button type="primary" icon="el-icon-thumb" :loading="couponLoad" @click="handleSubmit()">{{ couponLoad ? '保存中' : '提交' }}</el-button>
      </template>
    </moe-form>

    <goodsDialog
      :showDialog.sync="showDialog"
      type="SHOP"
      :defaultSelectIds="couponParams.configList && couponParams.configList.length ? couponParams.configList.map(({ goodsId }) => goodsId) : []"
      @close="handleClose">
    </goodsDialog>
  </moe-page>
</template>

<script>
import goodsDialog from '@/views/ActivityManage/components/goodsDialog.vue';
export default {
  name: 'ActivityManageCouponAdd',
  components: {
    goodsDialog
  },
  computed: {
    /** 是否为商品优惠券 */
    IS_SHOP_COUPON() {
      if (this.$route.query.type && this.$route.query.type === 'PART') {
        return true
      } else {
        return false
      }
    },
    IS_EDIT() {
      return this.couponParams.id ? true : false;
    },
  },
  data() {
    const checkCondition = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(`请输入满足金额`));
      } else {
        if(this.$moe_math.mathLessThanOrEqualTo(value, 0)) {
          return callback(new Error(`请输入大于0的满足金额`));
        } else if (this.$moe_math.mathGreaterThanOrEqualTo(value, 1000000)) {
          return callback(new Error(`满足金额必须小于100万`));
        } else {
          callback();
        }
      }
    }
    const checkDiscount = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(`请输入减免金额`));
      } else {
        let discount70 = this.$moe_math.mathDiv(Math.ceil(this.$moe_math.mathMul(this.$moe_math.mathMul(this.couponParams.condition, 0.7), 100)), 100);
        if (this.$moe_math.mathLessThanOrEqualTo(value, 0)) {
          return callback(new Error(`请输入大于0的减免金额`));
        } else if (this.$moe_math.mathGreaterThan(value, discount70)) {
          return callback(new Error(`减免金额不能超过满足金额的70%`));
        } else if (this.$moe_math.mathGreaterThanOrEqualTo(value, 1000000)) {
          return callback(new Error(`减免金额必须小于100万`));
        } else {
          callback();
        }
      }
    }
    const verify = this.$moe_verify.verifyForm
    const rules = {
      name: verify.isExtent(['请输入优惠券名称', '长度为 2 ~ 16位'], '2~16'),
      timeType: verify.isEmpty('请选择使用时间'),
      startTime: verify.isEmpty('请选择使用时间'),
      effectiveDays: verify.isEmpty('请输入领取后有效天数'),
      visible: verify.isEmpty('请选择是否开放领取'),
      condition: [{ required: true, validator: checkCondition, trigger: ['blur', 'change'] }],
      discount: [{ required: true, validator: checkDiscount, trigger: ['blur', 'change'] }],
      number: verify.isEmpty('请输入发行量'),
      quota: verify.isEmpty('请选择每人限领数量'),
      configList: verify.isEmpty('请添加活动商品'),
    };
    return {
      couponParams: {
        id: '',
        name: '',
        timeType: 'TIME_RANGE',
        effectiveDays: '',
        startTime: '',
        endTime: '',
        condition: '',
        discount: '',
        number: '',
        quota: '',
        level: 'ALL',
        visible: false,
        configList: [],
      },
      datetime: [],
      rules,
      couponLoad: false,
      itemKey: Math.random(),
      showDialog: false,
    }
  },
  methods: {
    changeCondition(value) {
      this.couponParams.condition = this.$moe_formatter.formatterMoney(value);
      this.$refs['couponForm'].validateField('discount');
    },
    changeTimeType(source) {
      switch (source) {
        case 'TIME_RANGE':
          this.couponParams.effectiveDays = '';
          break;
        case 'RELATIVE':
          this.couponParams.startTime = '';
          this.couponParams.endTime = '';
          this.datetime = [];
          break;
      }
    },
    /** 添加商品 确认 */
    handleClose(selectList) {
      selectList.forEach((item) => {
        let findItem = this.couponParams.configList.find(({ goodsId }) => item.id === goodsId)
        if (!findItem) {
          this.couponParams.configList.push({
            ...item,
            goodsId: item.id,
            id: '',
          })
        }
      })

      this.showDialog = false;
    },
    /** 撤出活动商品 */
    handleDelete({ name }, index) {
      this.$moe_layer.confirm(`您确定要撤出当前选项"${name}”吗？`, () => {
        this.couponParams.configList.splice(index, 1);
      });
    },
    /** 提交 */
    handleSubmit() {
      this.$refs['couponForm'].validate(() => {
        let params = {
          ...this.couponParams,
          level: this.IS_SHOP_COUPON ? 'PART' : 'ALL',
          configList: this.couponParams.configList.map(({ goodsId, id }) => {
            return {
              goodsId,
              id: id || ''
            }
          })
        }
        this.couponLoad = true;
        if (this.IS_EDIT) {
          this.req_updateCoupon(params);
        } else {
          this.req_addCoupon(params);
        }
      });
    },
    /** 创建优惠券 */
    req_addCoupon(params) {
      this.$moe_api.COUPON_API.addCoupon(params).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('新增优惠券成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      }).finally(() => {
        this.couponLoad = false;
      })
    },
    /** 修改优惠券 */
    req_updateCoupon(params) {
      this.$moe_api.COUPON_API.updateCoupon(params).then((data) => {
        if (data.code === 200) {
          this.$moe_msg.success('修改优惠券成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      }).finally(() => {
        this.couponLoad = false;
      })
    },
    /** 获取优惠券详情 */
    getCouponDetail() {
      if (this.$route.query.id) {
        this.$moe_api.COUPON_API.getCouponDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code === 200) {
            let r = data.result;
            this.couponParams = {
              id: r.id,
              name: r.name,
              timeType: r.timeType,
              effectiveDays: r.effectiveDays,
              startTime: r.startTime,
              endTime: r.endTime,
              condition: r.condition,
              discount: r.discount,
              number: r.number,
              quota: r.quota,
              level: r.level,
              configList: [],
              visible: r.visible,
            }

            this.datetime = [r.startTime, r.endTime];

            if (r.configList.length) {
              this.getQueryShopGoodsByIds(r.configList);
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    },
    /** 根据商品ID获取商品列表 */
    getQueryShopGoodsByIds(configList) {
      this.$moe_api.GOODS_API.getQueryShopGoodsByIds({ ids: configList.map(({ goodsId }) => goodsId).join(',') }).then((data) => {
        if (data.code === 200) {
          this.couponParams.configList = configList.map((item) => {
            let findGoodsItem = data.result.find(({ id }) => item.goodsId === id);
            return {
              ...findGoodsItem,
              goodsId: item.goodsId,
              id: item.id,
            }
          })
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    }
  },
  mounted() {
    this.getCouponDetail();
  },
}
</script>

<style lang="scss">

</style>